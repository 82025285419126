<template>
  <div class="pb-3xl">
    <Breadcrumbs
      v-if="breadcrumbItems.length > 0"
      :items="fullBreadcrumbItems"
      class="mx-xs md:mx-md my-2xs md:my-xs"
      data-fs="productBreadcrumbs"
    />
    <Stack
      v-if="product"
      justify="center"
      align="start"
      class="product-page bg-grey-lighter"
      direction="col"
      :breakpoints="{ direction: { md: 'row' }, justify: { md: 'between' } }"
    >
      <Stack class="pb-none w-full" gap="sm" direction="col">
        <section
          :class="[
            'w-full',
            product[0].images && product[0].images.length > 1
              ? 'h-[calc(100vw + 34px)] md:h-[min(calc(50vw + 5rem), 816px)]'
              : 'aspect-square',
          ]"
        >
          <ImageGallery :image="product[0].images" data-fs="pdpImage" />
        </section>
        <ProductDetails :product="product" :price="priceRef" :product-details="productDetails" class="sm:hidden" />
        <Stack
          direction="col"
          class="product-description w-full px-xs sm:px-none"
          data-test-id="productDescription"
          data-fs="pdpProductDescription"
        >
          <Heading size="md" class="text-charcoal-default">Product Overview</Heading>
          <div v-if="product && product[0]?.description" class="rich-text" v-html="product[0].description" />
          <Heading v-if="files.length > 0" size="xs" weight="semi">Downloads</Heading>
          <Stack direction="col" data-test-id="pdpFiles" data-fs="pdpFiles">
            <div v-for="(file, index) in files" :key="index">
              <a :href="file.value" target="_blank">
                <Stack align="center" class="cursor-pointer">
                  <Icon name="download" :size="16" color="#1e55c0" />
                  <Text class="text-mkm-blue-light">{{ getCleanFileName(file.name) }}</Text>
                </Stack>
              </a>
            </div>
          </Stack>
          <Specifications />
        </Stack>
      </Stack>
      <ProductDetails
        v-if="product.length > 0"
        :product="product"
        :price="priceRef"
        :product-details="productDetails"
        class="hidden sm:block"
      />
    </Stack>

    <section ref="relatedProductsRef" class="min-h-[600px] md:min-h-[800px]">
      <LazyRelatedProducts
        v-if="relatedProducts && relatedProductsLoaded"
        :products="relatedProducts"
        :breadcrumbs="breadcrumbItems"
        :prices="relatedProductsPrices"
        title="You may also be looking for"
        :cta="{ text: 'Shop all', link: breadcrumbItems[breadcrumbItems.length - 1]?.href }"
      />
    </section>

    <LazySpecificationsTray
      v-if="product && product[0] && product[0].custom_fields"
      :specification-fields="product[0].custom_fields ?? []"
    />
    <LazySustainabilityTray
      v-if="product && product[0] && product[0].custom_fields"
      :product-sustainability-data="product[0].custom_fields"
    />
    <LazyTallyTray
      v-if="productDetails && product"
      :product-info="product[0]"
      :product-default-pricing="productDetails"
      :price="priceRef"
      :selected-branch-id="selectedBranchId"
    />
    <LazyDropShipTray />
  </div>
</template>
<script lang="ts" setup>
import useJsonld from "~/composables/useJsonId";
import { BreadcrumbItemProps } from "@/components/UI/Breadcrumbs/breadcrumbTypes";
import { useGA4EcommerceEvents } from "mkm-gtm";
import { removeTrailingSlash } from "mkm-avengers";
import { Category, CustomFields } from "mkm-types";
import { IMAGES } from "~/constants/images";

const { selectedBranch, selectedBranchId } = useBranches();
const { user } = useUser();
const route = useRoute();
const { pushGA4EcommerceEvent, GA4EcommerceEvents } = useGA4EcommerceEvents();
const { fetchRelatedProducts } = useSearchAndMerch();
const { getPrice, fetchPrice, prices: productPrice } = usePrices();

const relatedProducts = ref<any>({});
const relatedProductsLoaded = ref<boolean>(false);
const relatedProductsRef = ref<HTMLElement>();
const relatedProductsPrices = ref<any>();
const categoryTree = useCategoryTree();
const priceRef = ref();
const categories = ref<Category[]>([]);
const files = ref<CustomFields[]>([]);
const pageTitle = ref("");
const pageMeta = ref<{ name: string; hid: string; content: any }[]>([]);
const pageJson = ref();

useHead({
  bodyAttrs: {
    class: "bg-neutral-lighter",
  },
});

const getCleanFileName = (fileName: string) => fileName.replace(/^file_/i, "");

//Get the product code from the URL
const getProductId = computed(() => {
  const inputString = route.params.productHandle.toString();
  let lastIndex = inputString.lastIndexOf("-");
  return inputString.slice(lastIndex + 1).toUpperCase();
});

const { fetchProduct, data: product, productDetails } = useProduct(getProductId.value);

await fetchProduct();

if (product?.value && product.value.length > 0) {
  pageTitle.value = product.value[0].name;
  pageMeta.value = [{ name: "description", hid: "description", content: product.value[0].meta_description }];
  files.value =
    product.value[0].custom_fields && product.value[0].custom_fields.length > 0
      ? product.value[0].custom_fields.filter((field) => field.value.includes("pdf"))
      : [];
}

const loadRelatedProducts = async () => {
  if (Object.keys(productDetails.value).length > 0) {
    await fetchRelatedProducts(getProductId.value).then(async (data) => {
      relatedProducts.value = data;
      if (user.value && relatedProducts.value) {
        const collectProductIds = relatedProducts.value?.items.map((i: any) => i.itemId.id);
        relatedProductsPrices.value = await getPrice(collectProductIds.join(","));
      } else {
        relatedProductsPrices.value = [];
      }
    });
  }

  relatedProductsLoaded.value = true;
};

pageJson.value = {
  "@context": "https://schema.org",
  "@type": "Product",
  name: pageTitle,
  description: product?.value?.[0]?.description.replaceAll("<p>", "").replaceAll("</p>", ""),
  image: product?.value?.[0]?.images?.[0]?.url_thumbnail || IMAGES.PLACEHOLDER,
  offers: productPrice.value?.[0]?.price_inc_vat
    ? {
        "@type": "Offer",
        priceCurrency: "GBP",
        price: productPrice.value?.[0]?.price_inc_vat,
        ...(product?.value?.[0].availability === "available" && { availability: "https://schema.org/InStock" }),
      }
    : undefined, // Exclude `offers` if no price to avoid invalid schema errors
};
const { inject, script: jsonScript } = useJsonld();
inject(pageJson.value);

useHead({
  title: pageTitle,
  meta: pageMeta,
  script: jsonScript,
});

const findCategoryById = (data: Category[], id: number): Category | undefined => {
  const iter = (categories: Category[]): Category | undefined => {
    for (const category of categories) {
      if (category.id === id) {
        return category;
      }
      if (Array.isArray(category.children)) {
        const result = iter(category.children);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };
  return iter(data);
};

function addCategoryIfVisible(list: Category[], id: number) {
  const category = findCategoryById(list, id);
  if (category && category.is_visible) {
    categories.value.push(category);
  }
}

function addParentCategories(list: Category[], categoryIndex: number) {
  const category = categories.value[categoryIndex];
  if (category?.parent_id) {
    addCategoryIfVisible(list, category.parent_id);
  }
}

function getProductCategories() {
  categories.value = [];

  if (product.value && product.value[0]?.categories?.length > 0) {
    const productCategories = product.value[0].categories;

    if (categoryTree?.data?.value) {
      const list = categoryTree.data.value as Category[];

      addCategoryIfVisible(list, productCategories[0]);

      for (let i = 0; i < 3; i++) {
        addParentCategories(list, i);
      }

      categories.value.reverse();
    }
  }
}

if (Object.keys(productDetails.value).length > 0) {
  getProductCategories();
}

const breadcrumbItems: BreadcrumbItemProps[] = categories.value.map((category: Category) => ({
  title: category.name,
  href: removeTrailingSlash(category.url),
  disabled: !category.is_visible,
}));

const fullBreadcrumbItems = computed(() => {
  return [...breadcrumbItems, { title: pageTitle.value, href: "/product" }];
});

const findProductData = (name: string) => {
  return productDetails.value.customAttrs.find((item: any) => item.name === name);
};

function extractAttributeValue(objects: {}, attributeName: string): any {
  if (Array.isArray(objects)) {
    const objectWithAttribute = objects.find((obj) => obj.name === attributeName);
    return objectWithAttribute ? objectWithAttribute.values[0] : null;
  }
  return null;
}
const getStockType = (availabilityFlag: number) => {
  return availabilityFlag === 1 ? "BRANCH STOCKED" : availabilityFlag === 4 ? "DROPSHIP" : null;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
onMounted(async () => {
  if (product?.value) {
    const url = product?.value?.[0]?.custom_url.url;
    if (!route.path.includes(url)) {
      return navigateTo(url, { redirectCode: 301 });
    }
  }

  if (user.value && getProductId.value) {
    await fetchPrice(getProductId.value);

    priceRef.value = productPrice?.value?.[0] ?? null;
  }

  if (relatedProductsRef?.value && !relatedProductsLoaded.value) {
    const lazyRelatedProductComponent = new IntersectionObserver(
      (entries) => {
        const component = entries[0];

        if (component.isIntersecting) {
          loadRelatedProducts();

          lazyRelatedProductComponent.disconnect();

          lazyRelatedProductComponent.unobserve(relatedProductsRef?.value!);
        }
      },
      { rootMargin: "10px" },
    );

    lazyRelatedProductComponent.observe(relatedProductsRef?.value!);
  }

  setTimeout(async () => {
    const userCategory = user.value?.account.business_type.split("|");
    const isTrade = userCategory?.[0] === "NTrade" ? "NON-TRADE" : userCategory?.[0] === "Trade" ? "TRADE" : null;

    await pushGA4EcommerceEvent(GA4EcommerceEvents.VIEW_ITEM, {
      logged_in: user.value !== null || false,
      user_id: user.value?.id ?? null,
      customer_category_1: user ? isTrade : "Guest",
      customer_category_2: userCategory?.[1] ?? null,
      customer_category_3: userCategory?.[2] ?? null,
      account_type: user.value?.account.type ?? null,
      category: categories.value[0]?.name ?? null,
      account_owning_branch: user.value?.account.branch_id ?? null,
      selected_branch: selectedBranch.value?.name ?? null,
      stock_type: getStockType(Number(extractAttributeValue(productDetails.value.customAttrs, "availability_flag"))),
      page_type: "product",
      ecommerce: {
        currency: "GBP",
        value: Number.parseFloat(findProductData("price_inc_vat")?.["values"][0]) ?? null,
        items: [
          {
            item_id: productDetails.value?.itemId?.id,
            bigcommerce_item_id: product?.value?.[0]?.id.toString() ?? null,
            item_name: product?.value?.[0]?.name,
            price: Number.parseFloat(findProductData("price_inc_vat")?.["values"][0]) ?? null,
            price_ex_vat: Number.parseFloat(findProductData("price")?.["values"][0]) ?? null,
            quantity: 1,
          },
        ],
      },
    });
  }, 2000);
});

onBeforeUnmount(() => {
  relatedProducts.value = {};
  relatedProductsLoaded.value = false;
  relatedProductsPrices.value = null;
});

//Watchers
watch(
  selectedBranch,
  async (branch) => {
    if (branch && process.client) {
      await Promise.all([
        fetchProduct(),
        await fetchRelatedProducts(getProductId.value).then(async (data) => {
          relatedProducts.value = data;
          if (user.value && relatedProducts.value) {
            const collectProductIds = relatedProducts.value?.items.map((i: any) => i.itemId.id);
            relatedProductsPrices.value = await getPrice(collectProductIds.join(","));
          } else {
            relatedProductsPrices.value = [];
          }
        }),
      ]);
    }
  },
  { immediate: true },
);

watch(
  () => [user, relatedProducts],
  async () => {
    if (user.value && relatedProducts.value?.items) {
      const collectProductIds = relatedProducts.value?.items.map((i: any) => i.itemId.id);
      relatedProductsPrices.value = await getPrice(collectProductIds.join(","));
    } else {
      relatedProductsPrices.value = [];
    }
  },
  { deep: true },
);

watch(user, async () => {
  if (user.value && getProductId.value) {
    await fetchPrice(getProductId.value);

    priceRef.value = productPrice?.value?.[0] ?? null;
  }
});
</script>

<style>
.product-page {
  max-width: 1520px;
  margin: 0 auto;
  width: 100%;
}

@media (min-width: 768px) {
  .product-page {
    padding: 0 16px;
  }
}

.nowrap {
  white-space: nowrap;
}
</style>
